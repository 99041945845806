import React, { FC } from 'react'
import styled, { StyledProps } from 'styled-components'
import { Box } from '@ucheba/ui/components/Box/desktop'
import { List, ListItem } from '@ucheba/ui/components/List/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Formik, Field, ErrorMessage } from 'formik'
import { TextField } from '@ucheba/ui/components/TextField/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { ErrorText } from '@ucheba/ui/components/ErrorText/desktop'
import { EDataKeys } from '@ucheba/utils/types/dataKeys'
import { Form } from '@ucheba/ui/components/Form/desktop'
import { authPhoneCodeSelectors } from '@ucheba/store/auth/phone/code'
import { useSelector } from 'react-redux'
import { EButtonColorByTheme, EInputsColor, ILidMagnetFormProps } from '../types'
import ImgLarge from '../assets/img__main_small.png'
import ImgSmall from '../assets/img__main.png'
import { useLidMagnetForm } from '../bll/useLidMagnetForm'
import PhoneConfirmDialog from './PhoneConfirmDialog'
import MessengersDialog from './MessengersDialog'

const Block = styled<StyledProps<any>>(Box)``

const Img = styled.img`
  width: 16px;
`

const ImgMain = styled.img`
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  right: 0;
`

const TextST = styled(Text)`
  letter-spacing: 1.4px;
`

const GridColSC = styled<StyledProps<any>>(GridCol)`
  display: ${(props) => (props.showInputs ? 'block' : 'none')};
`

const LidMagnetForm: FC<ILidMagnetFormProps> = (props) => {
  const {
    title,
    list,
    text,
    size = 'large',
    funnel,
    productType,
    requestTarget,
    onSubmitForm,
    onCheckedCode,
    withMessengers = true,
    color = 'plum',
    children,
    overTitle,
    buttonText,
    imgUrl,
    titleSize = 'h2',
    showInputs = true,
  } = props

  const errorPhone = useSelector(authPhoneCodeSelectors.error)

  const {
    initialValues,
    onSubmit,
    getImg,
    onPhoneConfirmSubmit,
    onPhoneConfirmClose,
    needConfirmPhone,
    needShowMessengers,
    onMessengersDialogClose,
    phone,
    isLoading,
  } = useLidMagnetForm(funnel, productType, requestTarget, onSubmitForm, onCheckedCode)

  return (
    <Block color={color} spacing='xlarge'>
      {children}

      {title || text || list ? (
        <>
          <Grid spacing='small'>
            <GridCol uxs={size === 'small' ? 16 : 12}>
              {overTitle && (
                <>
                  <Spacing spacing='usmall' />

                  <TextST uppercase>{overTitle}</TextST>

                  <Spacing spacing='usmall' />
                </>
              )}

              {title && (
                <Text fontSize={titleSize} lineHeight={titleSize} fontWeight='extrabold'>
                  {title}
                </Text>
              )}

              {text && (
                <>
                  <Spacing spacing='xxsmall' />

                  <Text>{text}</Text>
                </>
              )}

              {list && (
                <>
                  <Spacing spacing='xxsmall' />

                  <List spacing='xxsmall'>
                    {list.map((txt, key) => {
                      return (
                        <ListItem
                          alignItems='flex-start'
                          key={key}
                          StartIcon={getImg(key) ? <Img src={getImg(key)} /> : undefined}
                        >
                          <Text
                            fontSize='h4'
                            lineHeight='h4'
                            fontWeight='bold'
                            color='white'
                          >
                            {txt}
                          </Text>
                        </ListItem>
                      )
                    })}
                  </List>
                </>
              )}
            </GridCol>

            <GridCol uxs={size === 'small' ? 8 : 12}>
              <div
                style={{
                  position: 'relative',
                  height: '100%',
                }}
              >
                <ImgMain src={imgUrl || (size === 'small' ? ImgSmall : ImgLarge)} />
              </div>
            </GridCol>
          </Grid>

          <Spacing spacing='small' />
        </>
      ) : null}

      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {(): JSX.Element => (
          <Form fillByProfile>
            <Grid spacing='small'>
              <GridColSC showInputs={showInputs} uxs={size === 'large' ? 12 : 8}>
                <Field
                  as={TextField}
                  name={EDataKeys.name}
                  label='Имя'
                  color={EInputsColor[color]}
                  required
                />

                <ErrorMessage component={ErrorText} name={EDataKeys.name} />
              </GridColSC>

              <GridColSC showInputs={showInputs} uxs={size === 'large' ? 12 : 8}>
                <Field
                  as={TextField}
                  name={EDataKeys.phone}
                  label='Телефон'
                  color={EInputsColor[color]}
                  required
                  type='tel'
                />

                <ErrorMessage component={ErrorText} name={EDataKeys.phone} />

                {errorPhone && (
                  <ErrorText color={color === 'gold' ? 'red' : 'white'}>
                    {errorPhone?.message}
                  </ErrorText>
                )}
              </GridColSC>

              <GridCol uxs={size === 'large' ? 24 : 8}>
                <Button
                  color={EButtonColorByTheme[color] || 'gold'}
                  block
                  type='submit'
                  progress={isLoading}
                >
                  {buttonText || 'Отправить'}
                </Button>
              </GridCol>
            </Grid>
          </Form>
        )}
      </Formik>

      {phone && needConfirmPhone ? (
        <PhoneConfirmDialog
          open={needConfirmPhone}
          phone={phone}
          onSubmit={onPhoneConfirmSubmit}
          onClose={onPhoneConfirmClose}
          isLoading={isLoading}
        />
      ) : null}
      <MessengersDialog
        open={needShowMessengers && withMessengers}
        onClose={onMessengersDialogClose}
      />
    </Block>
  )
}

export default LidMagnetForm
